.events {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding: 10px 10vw;
    margin: 0;
    list-style: none;
  }
  
  .p-event-title {
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1.5em;
  }
  

.event-container {
    margin-left: 17.5%;
    margin-right: 25%;
  }
  
  .event-tagline {
    font-weight: 900;
    font-size: 120px;
    text-align: left;
  }
  
  .text-highlight {
    color: #cf0754;
  }
  
  @media screen and (max-width: 800px) {
    .event-container {
      margin-left: 6.5%;
      margin-right: 25%;
    }
    .event-tagline {
      font-weight: 900;
      font-size: 70px;
      text-align: left;
    }
  }
  
  @media screen and (max-width: 600px) {
    .event-container {
      margin-left: 6.5%;
      margin-right: 25%;
    }
    .event-tagline {
      font-weight: 900;
      font-size: 60px;
      text-align: left;
    }
  }
  
  @media screen and (max-width: 400px) {
    .event-container {
      margin-left: 11%;
      margin-right: 25%;
    }
    .event-tagline {
      font-weight: 900;
      font-size: 50px;
      text-align: left;
    }
  }
  