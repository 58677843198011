@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
/* font-family: 'Montserrat', sans-serif; */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");
/* font-family: 'Inter', sans-serif; */

.event_card {
  width: 330px;
  height: auto;
  border-radius: 10px;
  margin: 30px;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.event_event_image {
  width: 100%;
  max-width: 328px;
  aspect-ratio: 16/9;
  /* height: 182px; */
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.event_content {
  padding: 11px 16px;
}
.event_top {
  height: 93px;
}
.event_top h2 {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  margin-bottom: 8px;
}
.event_top p {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #222222;
}
.event_bottom {
  display: flex;
  justify-content: space-between;
}
.event_bottom span {
  margin: auto 0;
}
.event_card span p {
  font-size: 13px;
  display: inline;
  margin-left: 7px;
  color: #222222;
  margin-top: 10px;
  margin-bottom: 0;
}
.event_bottom a {
  background-color: #3d5aff;
  color: #fff;
  padding: 10px;
  font-size: 13px;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
}
.event_button {
  color: #31343c;
  width: auto;
  border: 1px dashed #6c757d;
  height: 40px;
  padding: 7px 16px 0;
  background-color: transparent;
  border-radius: 5px;
  font-size: 16px;
  transition: 0.25s;
  margin: 10px;
}

@media screen and (max-width: 650px) {
  .event_card {
  margin: 10px;
  }
}
