.cc-message-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #ffffff;
  justify-content: space-evenly;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.cc-image-container {
  background: rgb(255, 255, 255);
  width: 40%;
  height: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 50px;
}

.cc-image {
  width: 100%;
  height: auto;
  border-radius: 50px;
}

.cc-message {
  background: rgb(255, 255, 255);
  padding: 5px;
  width: 40%;
  height: 400px;
  margin-top: 40px;
  color: rgb(0, 0, 0);
  font-size: 1.5rem;
  text-align: left;
  margin-bottom: 40px;
}

.activities-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  background-color: #000000;
  height: 100%;
  padding: 3%;
}

.card-1 {
  height: 500px;
  width: 400px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    120deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 33, 121, 1) 35%,
    rgba(0, 204, 255, 1) 100%
  );
  border-radius: 20px;
  margin: 3%;
}

.icon-box {
  background-color: hsl(0, 0%, 100%);
  height: 80px;
  width: 80px;
  margin-left: 40%;
  margin-top: 20%;
}

.iconbox-item {
  margin-top: 40%;
  margin-left: 35%;
}

.card-title {
  margin-top: 5%;
  color: #ffffff;
  font-size: 30px;
  text-align: center;
  font-weight: bold;
}

.activity-headline {
  color: #ffffff;
  font-size: 3rem;
  font-weight: bold;
  background-color: #000000;
  text-align: center;
}

.card-text {
  color: #ffffff;
  padding: 8%;
  text-align: center;
  font-weight: bold;
}

.about-section1 {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 33, 121, 1) 35%,
    rgba(0, 204, 255, 1) 100%
  );
  height: 150px;
  color: #ffffff;
}

.about-section-title {
  font-family: "Inter", "sans-serif";
  font-weight: bold;
  font-size: 3rem;
  text-align: center;
  padding-top: 35px;
}

.partner-image-container {
  background: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  align-items: space-around;
  justify-content: space-around;
}

.partner-img {
  border-radius: 50px;
  width: 60%;
  margin-left: 20%;
}

@media screen and (max-width: 820px) {
  .cc-image-container {
    width: 90%;
  }

  .cc-message {
    width: 90%;
    font-size: 1rem;
    margin-bottom: 20px;
  }
}
