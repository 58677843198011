:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.btn--primary {
  background-color: var(--primary);
  color: #000000;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #ffffff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn-medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26 px;
  font-size: 20px;
}

.btn--mobile {
  text-align: center;
  border-radius: 4px;
  width: 80%;
  text-decoration: none;
  font-size: 24px;
  background-color: transparent;
  color: #ffffff;
  padding: 14px 20px;
  border: 1px solid #ffffff;
  transition: all 0.3s ease-out;
}

.btn--wide {
  padding: 12px 64px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover,
.btn--mobile:hover {
  transition: all 0.3s ease-out;
  background: #ffffff;
  color: #000000;
}

.btn--wide:hover {
  color: #ffffff;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 33, 121, 1) 35%,
    rgba(0, 204, 255, 1) 100%
  );
  transition: all 5s ease-out;
}

.blue {
  background-color: #808080;
  color: #ffffff;
  border: none;
}

.red {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 33, 121, 1) 35%,
    rgba(0, 204, 255, 1) 100%
  );
  color: #ffffff;
  border: none;
}

.primary {
  background-color: #000000;
  color: #ffffff;
  border: none;
}

.primary:hover {
  background-color: #ffffff;
  color: #000000;
  border: none;
}

.green {
  background-color: #25ce4a;
  color: #ffffff;
  border: none;
}

.btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
}
