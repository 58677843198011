.container-head {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
}

#block1 {
  background: rgb(0, 0, 0);
  padding: 5px;
  width: 100%;
  height: 200px;
  line-height: 150px;
  color: white;
}

#block2 {
  background: rgb(0, 55, 126);
  padding: 5px;
  width: 200px;
  height: 200px;
  line-height: 150px;
  color: white;
}

#block3 {
  background: rgb(241, 9, 106);
  padding: 5px;
  width: 150px;
  height: 200px;
  line-height: 150px;
  color: white;
}

#block4 {
  background: rgb(243, 247, 24);
  padding: 5px;
  width: 100px;
  height: 200px;
  line-height: 150px;
  color: white;
}

#block5 {
  background: rgb(91, 240, 111);
  padding: 5px;
  width: 50px;
  height: 200px;
  line-height: 150px;
  color: white;
}

.page-title {
  font-family: "Inter", "sans-serif";
  font-weight: bold;
  font-size: 4rem;
  margin-left: 22%;
  margin-top: 1%;
}

@media screen and (max-width: 800px) {
  .page-title {
    font-size: 1.5rem;
    margin-left: 1.5rem;
    margin-top: 1%;
  }
}
