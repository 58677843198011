.item1 {
  color: rgb(255, 255, 255);
  margin-bottom: 20px;
}

.item-font {
  font-size: 100px;
}

.slider {
  position: relative;
  width: 800px;
  margin: 0 auto;
  height: 500px;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 20px;
}

.slider-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.slide {
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.arrow {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
  border-radius: 50%;
  cursor: pointer;
  transition: transform ease-in 0.1s;
}

.nextArrow {
  position: absolute;
  top: 50%;
  right: 25px;
  z-index: 999;
  color: rgb(3, 3, 3);
}

.backArrow {
  position: absolute;
  top: 50%;
  left: 25px;
  z-index: 999;
  color: rgb(0, 0, 0);
}

.fa-arrow-right:before,
.fa-arrow-left:before {
  color: #222;
}

#vids {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#vids a:first-of-type {
  margin-bottom: 5px;
}

.slider-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 40px;
  padding-left: 10%;
  padding-right: 10%;
  background: rgb(0, 36, 20);
  background: linear-gradient(
    90deg,
    rgba(0, 36, 20, 1) 0%,
    rgba(41, 149, 136, 1) 35%,
    rgba(11, 201, 201, 1) 100%
  );
}

@media screen and (max-width: 500px) {
  .item-font {
    font-size: 70px;
  }

  .slider {
    width: 300px;
    height: 200px;
    border-radius: 15px;
  }

  .slider-wrapper {
    width: 300px;
    height: 200px;
  }

  .slide {
    width: 300px;
    height: 200px;
  }

  .arrow {
    height: 30px;
    width: 30px;
  }

  .nextArrow {
    position: absolute;
    top: 30%;
    right: 30px;
    z-index: 999;
    color: rgb(3, 3, 3);
  }

  .backArrow {
    position: absolute;
    top: 30%;
    left: 20px;
    z-index: 999;
    color: rgb(0, 0, 0);
  }
}
