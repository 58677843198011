.teams-general-container {
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  margin-bottom: 20px;
}

.teams-general-box {
  background-color: #000000;
  /* height: 160px; */
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.teams-general-title {
  color: #ffffff;
  font-size: 40px;
  margin-left: 10px;
}

.teams-general-subtitle {
  color: #ffffff;
  align-self: flex-start;
  margin-left: 10px;
  display: inline;
}



@media screen and (max-width: 800px) {
  .teams-general-box {
    
  }

  .teams-general-title {
    font-size: 30px;
    
  }

  .teams-general-subtitle {
    color: #fd9ebe;
    align-self: flex-start;
    margin-left: 10px;
  }
}

@media screen and (min-width:396px) {
  .linktag{
    float: right;
  }
}
