.projects_container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    background-color: #000000;
    height: 100%;
    min-height: 100vh;
    padding: 3%;

}
.MuiCardMedia-img{
    object-fit: contain !important;
}