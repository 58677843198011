.mcard-box {
  margin-left: 20%;
  margin-right: 20%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.mcard-container {
  display: flex;
  flex-direction: column;
  background-color: #000000;
  height: 390px;
  width: 240px;
  margin-bottom: 10%;
  margin-right: 1em;
}

.mcard-image {
  height: 300px;
  width: 200px;
  margin-left: 20px;
  margin-top: 20px;
  object-fit: cover;
}

.mcard-name {
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  font-size: 1em;
}

.mcard-title {
  color: #ec437b;
  text-align: center;
  font-weight: bold;
  font-size: 1em;
  height: 40px;
}

.mcard-flex {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.b1 {
  background: rgb(18, 7, 160);
  padding: 5px;
  width: 120px;
}

.b2 {
  background: rgb(252, 2, 127);
  padding: 5px;
  width: 80px;
}

.b3 {
  background: rgb(252, 229, 28);
  padding: 5px;
  width: 60px;
}

.b4 {
  background: rgb(91, 240, 111);
  padding: 5px;
  width: 40px;
}

/* Team Category Head */
.board-category1 {
  background-color: #ffd35b;
  height: 200px;
  margin-bottom: 40px;
}

.board-category2 {
  background-color: #6eff75;
  height: 200px;
  margin-bottom: 40px;
}

.board-category3 {
  background-color: #5bb0ff;
  height: 200px;
  margin-bottom: 40px;
}

.board-category4 {
  background-color: #ff5b71;
  height: 200px;
  margin-bottom: 40px;
}

.board-category5 {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(121, 9, 9, 1) 35%,
    rgba(255, 0, 144, 1) 100%
  );
  height: 200px;
  margin-bottom: 40px;
  color: #ffffff;
}

.board-category6 {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 33, 121, 1) 35%,
    rgba(0, 204, 255, 1) 100%
  );
  height: 200px;
  margin-bottom: 40px;
  color: #ffffff;
}

.category-title {
  font-family: "Inter", "sans-serif";
  font-weight: bold;
  font-size: 4rem;
  margin-left: 17.5%;
  padding-top: 50px;
}

@media screen and (max-width: 800px) {
  .category-title {
    font-size: 3rem;
    margin-left: 1.5rem;
  }
}
